import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../components/LayoutPreowned'
import YouTube from '../../components/homepage/YouTube'
import ProductGrid from '../../components/products/ProductGrid'

// markup
const UsedVacheronConstantinPatrimonyWatches = () => {
  const data = useStaticQuery(
    graphql`
      query queryUsedVacheronConstantinPatrimonyWatches {
        products: allStrapiProduct(
          filter: { brand: { eq: "Vacheron Constantin" }, qty: { gt: 0 } }
          sort: { fields: stock_date, order: DESC }
          limit: 8
        ) {
          nodes {
            ...ProductCard
          }
        }
      }
    `
  )
  return (
    <Layout
      siteTitle={'Used Vacheron Constantin Patrimony Watches for Sale'}
      canonical={'/fine-watches/other-watches-brands/vacheron-constantin/'}
      description={
        'Gray & Sons are experts in buying and selling second hand Vacheron Constantin Patrimony Watches with over 40 years of experience in the business'
      }
    >
      <div className="flex justify-center align-middle flex-col">
        <section>
          <div className="bg-gray-500 w-full flex justify-center">
            <a href="/fine-watches/other-watches-brands/vacheron-constantin/">
              <StaticImage
                src="../../images/preowned/used-vacheron-constantin-web-banner.jpg"
                alt="Used Vacheron Constantin Patrimony Watches Header"
                aria-label="Used Vacheron Constantin Patrimony Watches Header"
              />
            </a>
          </div>
        </section>

        <section className="p-5 lg:px-0 w-full flex justify-center">
          <div>
            <h1 className="text-3xl text-center">
              Buy, Sell, Trade, Consign, Repair, Restore <br />
              Vacheron Constantin Patrimony Watches
            </h1>
            <h2 className="text-3xl my-4">
              <a href="/fine-watches/other-watches-brands/vacheron-constantin/">
                Buying and Selling Pre-Owned Vacheron Constantin Patrimony Watches
              </a>
            </h2>
            <h2>
              Taking it back to the 1950s design of classicism and practicality, the Vacheron
              Constantin Patrimony watch collection is one that stays true to the meaning of
              elegance. In 2004, while most luxury watch brands were finding ways to introduce the
              most technologically advanced collection of wristwatches, Vacheron Constantin decided
              to target a different audience. Keeping the design of the original 1957 Vacheron
              Constantin Ref. 6179, the Patrimony line carries a slim round case with a sleek
              classic easy-to-read dial. Vacheron Constantin hoped to bring back appreciation for
              the original purpose of watches; keeping time. The first VC Patrimony model honored
              tradition by displaying time only and renouncing all excess complications, just
              sticking to root fundamentals. Later in 2013, VC chose to go ahead and drop a
              Patrimony model with a minute-repeater/ultra-thin movement. This Calibre 1731 watch
              broke 2 world records for the thinnest manual wound watch with a minute-repeating
              movement. This watch lies 8.09mm tall on the wrist and carries a movement only 3.9mm
              thick. For those who appreciate a timepiece with elegance and utmost functionality,
              the Vacheron Constantin Patrimony also comes in a Perpetual Calendar Ultra-Thin model,
              Retrograde Day-Date model, and a Moon-Phase Retrograde Date model. In the most
              excellent selection of fine metals, the VC Patrimony watch models are offered in
              diameters of 36mm to 42.5mm for all consumers to enjoy. Vacheron Constantin took in
              mind all watch aficionados when creating this line, the Patrimony was even offered to
              the public with a manually-wound movement rather than solely automatically powered.
              It's unbelievable how successful Vacheron Constantin was in achieving the creation of
              all these varying Patrimony models while maintaining integrity and efficiency while
              holding a minimal and clean aesthetic. At a glance at any model in the VC Patrimony
              line, any true watch enthusiast can note how truly fine and pristine the layout and
              designs for these dials truly are. Just by looking at one, any watch lover can see why
              the Vacheron Constantin Patrimony line has been robbing eyes from Patek’s Calatrava
              Annual and Perpetual Calendar models. While the two share similar looks, Vacheron
              Constantin knew what they were doing when designing their Retrograde complications.
              The VC Patrimony Day-Date is one of the most interestingly laid-out calendar watches
              to grasp the attention of many! Whether celebrating a birthday, anniversary,
              promotion, or any other grand accomplishment, the Vacheron Constantin Patrimony is a
              watch perfect to commemorate the moment. Available in modernized his-and-hers case
              sizes, the VC Patrimony has a beautiful specialized model for each and every watch
              enthusiast.
            </h2>
            <h2 className="text justify mx-auto text-lg">
              <b className="text justify mx-auto text-2xl">
                {' '}
                History of Vacheron Constantin Patrimony Watches:
              </b>
              <br />
              Since its release, the Vacheron Constantin Patrimony has been a huge success. These
              elegant timepieces draw the attention of any watch enthusiast that comes near them.
              The Vacheron Constantin Patrimony collection is one of Vacheron Constantin’s versatile
              pieces appropriate for almost any setting. The umbrella of Vacheron Constantin
              Patrimony watches holds a variety of designs that fall under the following references:
            </h2>
            <br />
            <b>
              Popular Pre-Owned Used Vacheron Constantin Patrimony Watch References and Models:
            </b>{' '}
            <br />
            <li>
              {' '}
              &ensp;
              <a href="/fine-watches/other-watches-brands/vacheron-constantin/">
                Pre-Owned Vacheron Constantin Patrimony References: 81180/000R-B518,
                85180/000R-9248,81180/000R-9162,33093/3
              </a>{' '}
            </li>
            <li>
              {' '}
              &ensp;
              <a href="/fine-watches/other-watches-brands/vacheron-constantin/">
                Pre-Owned Vacheron Constantin Patrimony Retrograde Day-Date References:
                86020/000R-9239, 86020/000G-9508, 4000U/000R-B516, 86020/000P-9321
              </a>{' '}
            </li>
            <li>
              {' '}
              &ensp;
              <a href="/fine-watches/other-watches-brands/vacheron-constantin/">
                Pre-Owned Vacheron Constantin Patrimony Ultra-Thin References:
                43175/000R-B343,43175/000R-B519, 43175/000R-9687
              </a>{' '}
            </li>
            <br />
            <p>
              {' '}
              <h2 className="text-lg mx-auto text-justify ">
                <strong> Gray &amp; Sons is the Best Source for Buying and Selling </strong>
                <a href="/fine-watches/other-watches-brands/vacheron-constantin/">
                  Pre-Owned Vacheron Constantin Watches{' '}
                </a>{' '}
              </h2>
              <p>
                {' '}
                Since 1980, Gray and Sons Jewelers has been catering to clients all around the
                country with all their luxury needs. All of the services we provide are done in our
                very own store, here, in the heart of Miami, Florida. We are the top watch store in
                all of South Florida specializing in buying pre-owned Vacheron Constantin watches,
                Selling used Vacheron Constantin watches, repairing second-hand Vacheron Constantin
                watches, and trading genuine Vacheron Constantin timepieces. Gray and Sons Jewelers
                is the #1 all-stop shop for all Vacheron Constantin watch needs. Our store is
                located in South Florida in the city of Surfside across the street from the famous
                Bal Harbour Shops. Aside from buying Vacheron Constantin Watches and selling genuine
                pre-owned Vacheron Constantin watches, Gray and Sons is also a certified repair
                center for all Swiss-made wristwatches. We have six master-trained watchmakers
                in-house with over 150 years of combined experience to repair Vacheron Constantin
                watches, including the reparation of a Vacheron Constantin. We take in repairs for
                swiss-made watches daily!
                <br />
                Those looking to “get cash for my used Vacheron Constantin watch” or “sell my
                pre-owned Vacheron Constantin watch today” should visit{' '}
                <a href="/"> www.grayandsons.com </a> or{' '}
                <a href="https://sellusyourjewelry.com/"> www.sellusyourjewelry.com </a>
                to request a free watch quote to sell or trade-in for one of ours! We have a
                brick-and-mortar store located in the heart of Miami, Fl near cities like Sunny
                Isles, Haulover, Aventura, Hollywood, Fort Lauderdale, Miami Beach, Coconut Grove,
                and Coral Gables, Key Biscayne, and many more. On our website, we have LIVE
                representatives <a href="/chat-with-viktoria"> Viktoria</a> and{' '}
                <a href="/chat-with-rich"> Rich</a> who are specialists in buying and selling
                Vacheron Constantin watches. Our LIVE CHAT feature allows clients all around the
                nation to make all inquiries about Vacheron Constantin watches and possibly work out
                a deal in real-time. Gray and Sons Jewelers is the best and most reliable place to
                buy used pre-owned Vacheron Constantin watches. Our master-trained watchmakers
                assure every Vacheron Constantin watches in our inventory is certified authentic and
                serviced ready for wear with a 1-year warranty covering the movement.
              </p>
            </p>
          </div>
        </section>
      </div>
      <div className="w-full flex mx-auto justify-center pb-24 pt-24">
        <a href="/fine-watches/other-watches-brands/vacheron-constantin/">
          <button>Shop Vacheron Constantin Watches</button>{' '}
        </a>
      </div>

      <section>
        <h2 className="text-center pb-18 ">IN-STOCK Vacheron Constantin Watches:</h2>

        <br />

        <ProductGrid products={data.products.nodes} />
      </section>

      <section></section>

      <section className="py-20">
        <h2 className="text-center py-10">VISIT GRAY &amp; SONS ON YOUTUBE</h2>
        <div className="w-full flex justify-center">
          <YouTube
            className={'w-full lg:w-3/4 xl:w-1/2 py-10 aspect-video'}
            title={'You Tube Video Title '}
            src={'https://www.youtube.com/embed/F_VZZhwYmGY'}
          />
        </div>
      </section>
    </Layout>
  )
}

export default UsedVacheronConstantinPatrimonyWatches
